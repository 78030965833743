document.addEventListener("DOMContentLoaded", () => {
  const jobComponents = document.querySelectorAll(".js-job");
  const jobButtons = document.querySelectorAll(".js-job-button");
  const jobLists = document.querySelectorAll(".js-job-list");

  function removeActiveClassFromElements(elements) {
    elements.forEach((element) => {
      element.classList.remove("active");
    });
  }

  const originalButtonTexts = [];
  jobButtons.forEach((btn) => {
    originalButtonTexts.push(btn.innerHTML);
  });

  jobButtons.forEach((btn, index) => {
    btn.addEventListener("click", () => {
      const isBtnActive = btn.classList.contains("active");

      removeActiveClassFromElements(jobLists);
      removeActiveClassFromElements(jobButtons);
      removeActiveClassFromElements(jobComponents);

      if (!isBtnActive) {
        jobComponents[index].classList.add("active");
        jobLists[index].style.maxHeight = jobLists[index].scrollHeight + "px";
        btn.setAttribute("aria-expanded", "true");
        btn.classList.add("active");
        btn.innerHTML = "Réduire";
        jobLists[index].classList.remove("hide");
        setTimeout(() => {
          jobLists[index].classList.add("active");
        }, 500);

      } else {
        jobComponents[index].classList.remove("active");
        jobLists[index].style.maxHeight = null;
        btn.setAttribute("aria-expanded", "false");
        btn.classList.remove("active");
        btn.innerHTML = originalButtonTexts[index];
        setTimeout(() => {
          jobLists[index].classList.add("hide");
        }, 500);
      }
    });
  });
});
