// import { throttle, debounce } from 'throttle-debounce';

import './front/components/navigation';
import "./front/components/header";
import './front/components/job';
import './front/components/hero';
import AOS from 'aos';
document.addEventListener('DOMContentLoaded', () => {
  AOS.init({
    duration: 800,
  });
});
