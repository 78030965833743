document.addEventListener("DOMContentLoaded", () => {
  // Open megamenu
  const menuButtons = document.querySelectorAll(".js-menu-button");
  const megaMenus = document.querySelectorAll(".js-megamenu");
  const backButtons = document.querySelectorAll(".js-back-button");

  function removeActiveClassFromElements(elements) {
    elements.forEach((element) => {
      element.classList.remove("active");
    });
  }

  backButtons.forEach((backButton) => {
    backButton.addEventListener("click", () => {
      removeActiveClassFromElements(menuButtons);
      removeActiveClassFromElements(megaMenus);
      menuButtons.forEach((btn) => {
        btn.setAttribute("aria-expanded", "false");
      });
      setTimeout(() => {
        megaMenus.forEach((megaMenu) => megaMenu.classList.add("none"));
      }, 200);
    });
  });

  menuButtons.forEach((menuBtn) => {
    menuBtn.addEventListener("click", () => {
      removeActiveClassFromElements(megaMenus);
      const attributeBtn = menuBtn.getAttribute("aria-controls");

      if (menuBtn.classList.contains("active")) {
        removeActiveClassFromElements(menuButtons);
        removeActiveClassFromElements(megaMenus);
        menuButtons.forEach((btn) => {});
        megaMenus.forEach((megaMenu) => {
          setTimeout(() => megaMenu.classList.add("none"), 200);
        });
      } else {
        removeActiveClassFromElements(menuButtons);
        menuButtons.forEach((btn) => {
          const btnAttribute = btn.getAttribute("aria-controls");
          btn.setAttribute("aria-expanded", "false");
          if (btnAttribute === attributeBtn) {
            btn.classList.add("active");
            btn.setAttribute("aria-expanded", "true");
          }
        });

        megaMenus.forEach((megaMenu) => {
          const attributeMenu = megaMenu.getAttribute("id");
          if (attributeMenu === attributeBtn) {
            megaMenu.classList.remove("none");
            setTimeout(() => megaMenu.classList.add("active"), 200);
          } else {
            setTimeout(() => megaMenu.classList.add("none"), 200);
          }
        });
      }
    });
  });

  document.addEventListener("click", (event) => {
    let clickedInsideMegaMenu = false;

    megaMenus.forEach((megaMenu) => {
      if (event.target.closest(".js-megamenu") === megaMenu) {
        clickedInsideMegaMenu = true;
        return;
      }
    });

    if (!clickedInsideMegaMenu) {
      megaMenus.forEach((megaMenu) => {
        if (megaMenu.classList.contains("active")) {
          removeActiveClassFromElements(menuButtons);
          removeActiveClassFromElements(megaMenus);
          menuButtons.forEach((menuBtn) => {
            menuBtn.setAttribute("aria-expanded", "false");
            setTimeout(() => megaMenu.classList.add("none"), 200);
          });
        }
      });
    }
  });

  // Responsive

  const header = document.querySelector(".js-header");
  const burgerBtn = document.querySelector(".js-burger");
  const navMain = document.querySelector(".js-nav-main");
  const secondaryMenuButtons = document.querySelectorAll(".js-s-menu-button");

  if (burgerBtn) {
    burgerBtn.addEventListener("click", () => {
      if (header.classList.contains("open")) {
        burgerBtn.setAttribute("aria-expanded", "false");
        navMain.classList.remove("open");
        setTimeout(() => {
          header.classList.remove("open");
        }, 200);
      } else {
        burgerBtn.setAttribute("aria-expanded", "true");
        menuButtons.forEach((e) => {
          e.classList.remove("active");
        });
        megaMenus.forEach((e) => {
          e.classList.remove("active");
        });
        header.classList.add("open");
        setTimeout(() => {
          navMain.classList.add("open");
        }, 200);
      }
    });

    secondaryMenuButtons.forEach((secBtn) => {
      secBtn.addEventListener("click", () => {
        header.classList.remove("open");
        setTimeout(() => {
          navMain.classList.remove("open");
        }, 200);
      });
    });
  }
});
