document.addEventListener("DOMContentLoaded", () => {
  // Variable
  const $html = document.documentElement;
  const $body = document.querySelector("body");
  const $header = document.querySelector(".js-header");
  const $toggle = document.querySelector(".js-header-toggle");
  const $main = document.querySelector(".js-main");

  const is_admin = $body.classList.contains("admin-bar");

  if (is_admin) {
    $header.style.top = `${50}px`;
  }

  // Fonction

  let lastScrollTop = 0;
  let scrollingUp = false;
  let lastScrollPos = 0;
  let ticking = false;

  function headerState(currentScrollTop) {
    if (scrollingUp) {
      $header.style.top = `0`;
      $header.classList.remove("header-hidden");
    } else {
      $header.classList.add("header-hidden");
      $header.style.top = `-100%`;
    }

    lastScrollTop = currentScrollTop;
  }

  function handleScroll() {
    let scrollPos = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPos >= 150) {
      if (scrollPos >= lastScrollPos) {
        scrollingUp = false;
      } else {
        scrollingUp = true;
      }

      headerState(scrollPos);
      lastScrollPos = scrollPos <= 0 ? 0 : scrollPos;
    }

    ticking = false;
  }

  function requestTick() {
    window.requestAnimationFrame(handleScroll);
    ticking = true;
  }

  window.addEventListener("scroll", function () {
    if (!ticking) {
      requestTick();
    }
  });
});
