import Swiper from 'swiper';
import {Pagination, Autoplay} from 'swiper/modules';

const hero = document.querySelector('.js-hero');

const swiper = new Swiper(hero, {
  modules: [Pagination, Autoplay],
  centeredSlides: true,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: true,
  },
});
